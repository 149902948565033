import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { useIntl } from "react-intl";
import Layout from "../components/Layout";

export default function PrivacyPage() {
    const intl = useIntl();

    return (
        <Layout>
            <main className="static">
                <div className="row">
                    <h2>{intl.formatMessage({ id: "privacy.title" })}</h2>
                    <p><span>{intl.formatMessage({ id: "privacy.update" })}</span></p>
                    <div dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: "privacy.content" })
                    }} />  
                </div>
            </main>
        </Layout>
    );
}
